var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commentAdd" },
    [
      _c("p", { staticClass: "inappropriate" }, [
        _vm.inappropriate
          ? _c("span", [
              _vm._v(
                "\n      This comment has not been posted because the site\n      has identified an inappropriate word or term.\n    "
              ),
            ])
          : _vm._e(),
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "textBox",
        attrs: { rows: "5", type: "text", placeholder: _vm.bodyText },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = $event.target.value
          },
        },
      }),
      _c("ButtonSubmit", {
        attrs: { copy: "Send", enabled: _vm.buttonEnabled },
        on: { callback: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }