<template>
  <div class="attachment-item">
    <a href="#" @click.prevent="processFile">
      {{cleanFileName}}
    </a>
  </div>
</template>
<script lang="js">

export default {
  name: 'attachment-item',
  props: ['attachment'],
  data() {
    return {
      asset: {},
      cleanFileName: '',
    };
  },
  watch: {
    'attachment.id': {
      immediate: true,
      async handler(value) {
        await this.loadAsset(value);
      },
    },
  },
  methods: {
    downloadAsset() {
      return this.$store.dispatch(
        'assetStore/downloadAsset',
        this.attachment.id,
      );
    },
    cleanName(assetFileName, id) {
      this.cleanFileName = assetFileName.toString().replace(`_${id}`, '');
    },
    async processFile() {
      const fileRes = await this.downloadAsset();
      const url = window.URL.createObjectURL(new Blob([fileRes.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.asset.fileName);
      document.body.appendChild(link);
      link.click();
    },
    async loadAsset(attachmentId) {
      const respData = await this.$store.dispatch(
        'assetStore/getAssetDoNotStore',
        attachmentId,
      );
      this.cleanName(respData.fileName, respData.id);
      this.asset = respData;
    },
  },
  async mounted() {
    await this.loadAsset(this.attachment.id);
  },
};
</script>

<style lang="scss" scoped>
.attachment-item {
  a {
    color: $LincolnGray;

    &:hover {
      color: $LincolnOrange;
    }
  }
}
</style>
