<template>
  <div class="content-wrapper">
    <div class="brandStory">
      <div class="top-section">
        <StoryViewImage :story="brandStory" />
        <div class="divider-container">
          <div class="divider"></div>
        </div>
        <BrandStoryViewCopy class="story" :brandStory="brandStory" />
      </div>
      <div class="bottom-section">
        <div class="invisible"></div>
        <div class="wrapper">
          <BrandStoryAttachments class="attachments" :brandStoryId="id" />
          <BrandStoryOptions class="options" :brandStoryId="id" />
          <CommentList :brandStoryId="id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandStoryOptions from '../components/stories/brandStories/view/BrandStoryOptions.vue';
import StoryViewImage from '../components/stories/common/StoryViewImage.vue';
import BrandStoryAttachments from '../components/stories/brandStories/view/BrandStoryAttachments.vue';
import CommentList from '../components/comments/CommentList.vue';
import BrandStoryViewCopy from '../components/stories/brandStories/view/BrandStoryViewCopy.vue';

export default {
  name: 'BrandStory',
  props: ['id'],
  components: {
    BrandStoryViewCopy,
    StoryViewImage,
    BrandStoryOptions,
    BrandStoryAttachments,
    CommentList,
  },
  async created() {
    this.$analytics.trackPageView();
    await this.$store.dispatch('brandStoryStore/getBrandStory', this.id);
  },
  watch: {
    id(newId) {
      this.$store.dispatch('brandStoryStore/getBrandStory', newId);
    },
  },
  computed: {
    brandStory() {
      return this.$store.getters['brandStoryStore/currentBrandStory'];
    },
  },
};
</script>

<style lang="scss" scoped>
.brandStory {
  .top-section {
    display: flex;
    flex-direction: column;

    @include respond(md) {
      flex-direction: row;
    }

    .divider-container {
      display: none;

      @include respond(md) {
        display: block;
        padding: 0 4%;
      }

      .divider {
        width: 4px;
        padding: 0;
        background: rgb(35, 31, 32);
        height: 300px;
      }
    }

    .story {
      flex: 1;
    }
  }

  .bottom-section {
    display: flex;
    justify-content: flex-end;

    .invisible {
      display: none;
      width: 500px;

      @include respond(md) {
        display: block;
      }
    }

    .wrapper {
      width: 100%;

      .attachments {
        margin-top: 50px;
      }

      .options {
        margin: 40px 0;
      }
    }
  }
}
</style>
