<template>
  <div class="tenetList">
    <span>Tenet{{tenets[1] ? 's' : ''}}:</span>
    <ul>
      <li v-for="tenet in tenets" v-bind:key="tenet.id">{{tenet.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EffortlessTenetList',
  props: {
    tenets: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tenetList {
  display: flex;

  span {
    font-weight: $bold;
  }

  ul {
    margin: 0;
    padding-left: 10px;
    list-style-type: none;

    li {
      display: inline;

      &:after {
        content: ", ";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }
}
</style>
