<template>
  <div v-show="showList" class="commentList">
    <CommentAdd :brandStoryId="brandStoryId" />
    <CommentItem
      v-for="(comment, index) in this.comments"
      :key="'CommentItem' + comment.id"
      :comment="comment"
      :index="index"
    />
  </div>
</template>

<script>
import CommentItem from './CommentItem.vue';
import CommentAdd from './CommentAdd.vue';

export default {
  name: 'CommentList',
  props: ['brandStoryId'],
  components: {
    CommentItem,
    CommentAdd,
  },
  async created() {
    await this.$store.dispatch(
      'brandStoryStore/getCommentsForBrandStory',
      this.brandStoryId,
    );
  },
  computed: {
    comments() {
      return this.$store.getters['brandStoryStore/currentBrandStoryComments'];
    },
    showList() {
      if (this.$store.getters['brandStoryStore/currentBrandStory']) {
        if (
          this.brandStoryId
          === this.$store.getters['brandStoryStore/currentBrandStory'].id
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.commentList {
  display: flex;
  flex-direction: column;
}
.commentList > * {
  border-bottom: 0.5px solid $CommentLineBottom;
}
</style>
