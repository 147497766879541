<template>
  <div class="brandStoryAttachment" v-if="this.attachments">
    <div
      class="attachment"
      v-for="(attachment, key) in this.attachments"
      :key="key"
      :title="attachment.id"
    >
      <font-awesome-icon class="icon" :icon="['fal', 'paperclip']" size="2x" />
      <AttachmentItem class="text" :attachment="attachment" />
    </div>
  </div>
</template>

<script>
import AttachmentItem from '../../common/AttachmentItem.vue';

const attachmentsSort = (a, b) => {
  if (a.fileName < b.fileName) {
    return -1;
  }

  if (a.fileName > b.fileName) {
    return 1;
  }

  return 0;
};

export default {
  name: 'BrandStoryAttachments',
  props: ['brandStoryId'],
  components: {
    AttachmentItem,
  },
  computed: {
    attachments() {
      const temp = this.$store.getters['brandStoryStore/brandStoryAttachments'];
      return temp.sort(attachmentsSort);
    },
  },
  async created() {
    await this.$store.dispatch(
      'brandStoryStore/getAttachmentsForBrandStory',
      this.brandStoryId,
    );
  },
};
</script>

<style lang="scss" scoped>
.brandStoryAttachment {
  .attachment {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      color: $LincolnOrange;
      margin-right: 10px;
    }

    .text {
      @include font-size(1rem);
      font-weight: $bold;
    }
  }
}
</style>
