<template>
  <div class="areaList">
    <span>Business Area{{areas[1] ? 's' : ''}}:</span>
    <ul>
      <li v-for="area in areas" v-bind:key="area.id">{{area.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BusinessAreaList',
  props: {
    areas: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.areaList {
  display: flex;

  span {
    font-weight: $bold;
  }

  ul {
    margin: 0;
    padding-left: 10px;
    list-style-type: none;

    li {
      display: inline;

      &:after {
        content: ", ";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }
}
</style>
