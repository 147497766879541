<template>
  <div v-if="brandStory" class="brandStoryViewCopy">
    <div class="infoWrapper">
      <div class="userInfo" v-if="this.isPostedByUserNotAdmin">
        <div class="img">
          <div class="circleBorderProfileImg">
              <UserImageThumbnail :thumbnailAssetId="brandStory.submittedByUser.thumbnailAssetId"
                                  :class="'imgProfile'" />
          </div>
        </div>
        <div class="postInfo">
          <div>
            {{copy.postedBy}}
            <router-link
              @click.native="setProfileViewUser"
              :to="{path: 'profile', query: { id: brandStory.submittedByUser.id }}"
            >{{brandStory.submittedByUser.namePreferred}}</router-link>
          </div>
          <div>{{this.postedDate}}</div>
        </div>
      </div>
      <div class="postDate" v-else>{{this.postedDate}}</div>
      <div class="categories">
        <EffortlessTenetList v-show="brandStory.effortlessTenets.length > 0"
          class="tenets" :tenets="brandStory.effortlessTenets" />
        <BusinessAreaList v-show="brandStory.businessAreas.length > 0"
          class="businessAreas" :areas="brandStory.businessAreas" />
      </div>
    </div>
    <div class="title">{{brandStory.subject}}</div>
    <div v-html="brandStory.body" v-openLinksInNewTab></div>
  </div>
</template>

<script>
import copyBrandStoryTile from '../../../../data/brandStoryTitleCopy';
import EffortlessTenetList from './EffortlessTenetList.vue';
import BusinessAreaList from './BusinessAreaList.vue';
import openLinksInNewTab from '../../../../directives/openLinksInNewTab';

export default {
  name: 'BrandStoryViewCopy',
  props: ['brandStory'],
  components: {
    EffortlessTenetList,
    BusinessAreaList,
  },
  directives: {
    openLinksInNewTab,
  },
  data() {
    return {
      copy: copyBrandStoryTile,
    };
  },
  methods: {
    setProfileViewUser() {
      this.$store.dispatch(
        'profileViewStore/setProfile',
        this.brandStory.submittedByUser,
      );
    },
  },
  computed: {
    postedDate() {
      const pad = number => (number.length === 1 ? `0${number}` : number);
      const datePost = new Date(this.brandStory.submittedOn);
      return `${pad(datePost.getMonth() + 1)}/${pad(
        datePost.getDate(),
      )}/${datePost.getFullYear()}`;
    },
    isPostedByUserNotAdmin() {
      let bIsNotAdmin = true;
      if (this.brandStory.submittedByUser) {
        if (this.brandStory.submittedByUser.role) {
          bIsNotAdmin = this.brandStory.submittedByUser.role.name !== 'ADMIN';
        }
      }
      return bIsNotAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
.brandStoryViewCopy {
  color: rgb(50, 64, 74);
  display: flex;
  flex-direction: column;

  .infoWrapper {
    display: flex;
    flex-direction: column;

    @include respond(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .userInfo {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 10px;

      @include respond(md) {
        margin-top: 0;
      }

      .img {
        margin-right: 10px;

        .circleBorderProfileImg {
          background-color: rbga(0, 0, 0, 0);
          border: 3px solid $ProfileCircleBorder;
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          width: 30px;
          height: 30px;

          @include respond(md) {
            width: 45px;
            height: 45px;
          }

          .imgProfile {
            background-image: url("../../../../assets/images/temp/LEEDefaultProfileImage.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            display: inline-block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
      }

      .postInfo {
        font-weight: $bold;
        font-size: 0.75rem;

        a {
          text-decoration: none;
          color: rgb(50, 64, 74);

          &:hover {
            color: $LincolnOrange;
          }
        }

        @include respond(md) {
          font-size: 0.875rem;
        }
      }
    }

    .postDate {
      font-weight: $bold;
      font-size: 0.75rem;
      margin-top: 10px;

      @include respond(md) {
        font-size: 0.875rem;
        margin-top: 0;
      }
    }

    .categories {
      display: flex;
      flex-direction: column;
      color: $LincolnGrayLight;
      margin-top: 10px;
      font-size: 0.75rem;

      @include respond(md) {
        font-size: 0.875rem;
        margin-top: 0;
      }
    }
  }

  .title {
    @include font-size(2.875rem);
    margin: 20px 0 30px 0;
  }
}
</style>
