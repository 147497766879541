var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "brandStoryOptions" }, [
    _c(
      "div",
      { staticClass: "bs-like" },
      [
        _vm.likeStatus
          ? _c("font-awesome-icon", {
              staticClass: "icon liked",
              attrs: { icon: ["fas", "heart"], size: "2x" },
              on: { click: _vm.brandStoryLike },
            })
          : _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fal", "heart"], size: "2x" },
              on: { click: _vm.brandStoryLike },
            }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "favorite" },
      [
        _vm.favoriteStatus
          ? _c("font-awesome-icon", {
              staticClass: "icon favorited",
              attrs: { icon: ["fas", "bookmark"], size: "2x" },
              on: { click: _vm.brandStoryFavorite },
            })
          : _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fal", "bookmark"], size: "2x" },
              on: { click: _vm.brandStoryFavorite },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }