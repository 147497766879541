<template>
  <div class="brandStoryOptions">
    <div class="bs-like">
      <font-awesome-icon
        :icon="['fas', 'heart']"
        size="2x"
        @click="brandStoryLike"
        v-if="likeStatus"
        class="icon liked"
      />
      <font-awesome-icon
        :icon="['fal', 'heart']"
        size="2x"
        @click="brandStoryLike"
        v-else
        class="icon"
      />
    </div>
    <div class="favorite">
      <font-awesome-icon
        :icon="['fas', 'bookmark']"
        size="2x"
        @click="brandStoryFavorite"
        v-if="favoriteStatus"
        class="icon favorited"
      />
      <font-awesome-icon
        :icon="['fal', 'bookmark']"
        size="2x"
        @click="brandStoryFavorite"
        v-else
        class="icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandStoryOptions',
  props: ['brandStoryId'],
  data() {
    return {
      likeStatus: false,
      favoriteStatus: false,
    };
  },
  methods: {
    async brandStoryLike() {
      this.likeStatus = await this.$store.dispatch(
        'brandStoryLikeStore/setLikeStatus',
        this.brandStoryId,
      );
      this.$analytics.trackEventBtnPageWithMetaInfo('Like',
        { brandStoryId: this.brandStoryId });
    },
    async brandStoryFavorite() {
      this.favoriteStatus = await this.$store.dispatch(
        'brandStoryFavoriteStore/setFavoriteStatus',
        this.brandStoryId,
      );
      this.$analytics.trackEventBtnPageWithMetaInfo('Favorite',
        { brandStoryId: this.brandStoryId });
    },
    async getLikeStatus() {
      this.likeStatus = await this.$store.dispatch(
        'brandStoryLikeStore/getLikeStatus',
        this.brandStoryId,
      );
    },
    async getFavoriteStatus() {
      this.favoriteStatus = await this.$store.dispatch(
        'brandStoryFavoriteStore/getFavoriteStatus',
        this.brandStoryId,
      );
    },
  },
  async mounted() {
    await Promise.all([this.getLikeStatus(), this.getFavoriteStatus()]);
  },
};
</script>

<style lang="scss" scoped>
.brandStoryOptions {
  display: flex;
  justify-content: center;

  @include respond(md) {
    justify-content: flex-end;
  }

  .bs-like {
    margin: 0 25px 0 0;
  }

  .icon {
    color: #202020;

    &:hover {
      cursor: pointer;
    }

    &.liked {
      color: $LincolnOrange;
    }

    &.favorited {
      color: $LincolnGrayDark;
    }
  }
}
</style>
