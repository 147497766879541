<template>
  <div class="commentAdd">
    <p class="inappropriate">
      <span v-if="inappropriate">
        This comment has not been posted because the site
        has identified an inappropriate word or term.
      </span>
    </p>
    <textarea rows="5" class="textBox"
              type="text"
              v-model="value"
              :placeholder="bodyText" />
    <ButtonSubmit :copy="'Send'"
                  :enabled="buttonEnabled"
                  @callback="submit" />
  </div>
</template>

<script>
import Filter from 'bad-words';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';

const filter = new Filter();

export default {
  name: 'CommentAdd',
  props: ['brandStoryId'],
  components: {
    ButtonSubmit,
  },
  data() {
    return {
      bodyText: 'Enter Comment',
      inappropriate: false,
      value: '',
    };
  },
  mounted() {
    this.value = '';
  },
  computed: {
    buttonEnabled() {
      return !!(this.value && this.value.trim());
    },
  },
  methods: {
    async submit() {
      if (!this.buttonEnabled) {
        return;
      }

      this.inappropriate = this.value !== filter.clean(this.value);
      if (this.inappropriate) {
        this.$store.dispatch('buttonSubmitStore/done');
        return;
      }

      const commentObject = {
        brandStoryId: this.brandStoryId,
        text: this.value,
      };
      await this.$store.dispatch('brandStoryStore/saveCommentForBrandStory', commentObject);
      this.value = '';
      this.$store.dispatch('buttonSubmitStore/done');

      this.$analytics.trackEventBtnPageWithMetaInfo('CommentAdd',
        { brandStoryId: this.brandStoryId });
    },
  },
};
</script>

<style lang="scss" scoped>
.commentAdd {
  width: 100%;
  padding-bottom: 10px;

  .textBox {
    font-family: $ProximaNovaReg;
    @include font-size(1rem);
    padding: 15px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
  .commentSubmit {
    width: 30%;
    text-align: center;
    background-color: $LincolnOrange;
    color: white;
    cursor: pointer;
  }

  .inappropriate {
    color: $LincolnWarn;
  }
}
</style>
