<template>
  <div class="commentItem">
    <div class="circleBorderProfileImg" :class="engagementLevelClass">
      <UserImageThumbnail :thumbnailAssetId="comment.user.thumbnailAssetId" :class="'imgProfile'" />
    </div>
    <div class="copy">
      <div class="username">
        <router-link @click.native="setProfileViewUser"
                     :to="{ path: 'profile', query: { id: comment.user.id }}">
          {{comment.user.namePreferred}}
        </router-link>
      </div>
      <div class="text">{{comment.text}}</div>
      <div class="duration">Last edited {{changeToDays}}</div>
    </div>
    <div class="duration">{{changeToDays}}</div>
  </div>
</template>

<script>
import UserImageThumbnail from '../profile/UserImageThumbnail.vue';

export default {
  name: 'CommentItem',
  props: ['comment'],
  components: {
    UserImageThumbnail,
  },
  methods: {
    setProfileViewUser() {
      this.$store.dispatch('profileViewStore/setProfile', this.comment.user);
      this.$analytics.trackEventBtnPageWithMetaInfo('CommentProfile',
        { commentId: this.comment.id, userId: this.comment.user.id });
    },
  },
  computed: {
    changeToDays() {
      const datePost = new Date(this.comment.submittedOn);
      const dateNow = Date.now();
      const day = Math.round((dateNow - datePost) / (1000 * 60 * 60 * 24));
      if (day > 1) {
        return `${day} days ago`;
      }
      return `${day} day ago`;
    },
    engagementLevelClass() {
      return this.currentEngagementLevel
        ? this.currentEngagementLevel.name.replace(' ', '-').toLowerCase()
        : '';
    },
    currentEngagementLevel() {
      if (!this.engagementLevels) {
        return null;
      }

      const copy = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => this.comment.user.points >= el.pointsToUnlock);

      return copy
        ? copy.reverse()[0]
        : this.engagementLevels[this.engagementLevels.length - 1];
    },
    engagementLevels() {
      return this.$store.getters['engagementLevelStore/engagementLevels'];
    },
  },
};
</script>

<style lang="scss" scoped>
  .commentItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .circleBorderProfileImg {
      background-color: rbga(0, 0, 0, 0);
      border: 3px solid $ProfileCircleBorder;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-right: 15px;

      @include respond(md) {
        width: 45px;
        height: 45px;
        margin-right: 0;
      }
      .imgProfile {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        display: inline-block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }

    &.lincoln-savvy {
      border-color: rgb(46, 49, 146);
    }

    &.lincoln-influential {
      border-color: rgb(55, 34, 121);
    }

    &.lincoln-force {
      border-color: rgb(129, 130, 133);
    }

    &.lincoln-legend {
      border-color: rgb(36, 32, 33);
    }

    &.lincoln-notable {
      border-color: rgb(239, 64, 54);
    }

      &.lincoln-savvy {
          background-image: linear-gradient(rgb(46, 49, 146),rgb(15, 117, 188))
      }

      &.lincoln-influential {
          background-image: linear-gradient(rgb(55, 34, 121), rgb(102, 45, 145))
      }

      &.lincoln-force {
          background-image: linear-gradient(rgb(129, 130, 133), rgb(189, 190, 192))
      }

      &.lincoln-legend {
          background-image: linear-gradient(rgb(36, 32, 33), rgb(88, 88, 90))
      }

      &.lincoln-notable {
          background-image: linear-gradient(rgb(239, 64, 54), rgb(239, 109, 72))
      }
  }
  .copy {
    flex: 2 50%;
    .username {
      padding-top: 0px;
      font-size: 0.75rem;

      a {
        color: $CommentUserName;
        text-decoration: none;

        &:hover {
          color: $LincolnOrange;
        }
      }
    }
    .text {
      color: black;
      font-size: 0.8125rem;
    }
    .duration {
      color: $CommentDuration;
      white-space: nowrap;
      font-size: 0.75rem;
    }
  }
  .copy > * {
    padding: 10px;
  }
  .duration {
    color: $CommentDuration;
    font-size: 0.75rem;
  }
  // .extra {
  //   color: $CommentDuration;
  //   font-size: 40px;
  // }
  // .extra > * {
  //   margin-top: -30px;
  //   margin-bottom: -40px;
  // }
}
.commentItem > * {
  align-self: flex-start;
  margin: 1%;
}
</style>
