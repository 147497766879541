var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showList,
          expression: "showList",
        },
      ],
      staticClass: "commentList",
    },
    [
      _c("CommentAdd", { attrs: { brandStoryId: _vm.brandStoryId } }),
      _vm._l(this.comments, function (comment, index) {
        return _c("CommentItem", {
          key: "CommentItem" + comment.id,
          attrs: { comment: comment, index: index },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }