var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tenetList" }, [
    _c("span", [_vm._v("Tenet" + _vm._s(_vm.tenets[1] ? "s" : "") + ":")]),
    _c(
      "ul",
      _vm._l(_vm.tenets, function (tenet) {
        return _c("li", { key: tenet.id }, [_vm._v(_vm._s(tenet.name))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }