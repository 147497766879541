var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.attachments
    ? _c(
        "div",
        { staticClass: "brandStoryAttachment" },
        _vm._l(this.attachments, function (attachment, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "attachment",
              attrs: { title: attachment.id },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fal", "paperclip"], size: "2x" },
              }),
              _c("AttachmentItem", {
                staticClass: "text",
                attrs: { attachment: attachment },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }