<template>
  <div class="buttonSubmit">
    <button
      class="button"
      :class="{
        sized: !allowSizing,
        disable: submitting || !enabled
      }"
      :disabled="!enabled"
      @click.prevent="clickedButton"
    >
    {{copy}}
    </button>
    <div
      v-show="submitting"
      class="submitting"
    >
    <font-awesome-icon
      :icon="['fas', 'cog']"
      size="1x"
      class="fa-spin"
    />
    </div>
    <div
      class="message"
      v-show="message"
    >Request Failed
    </div>
  </div>
</template>

<script lang="js">

export default {
  name: 'ButtonSubmit',
  props: {
    copy: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowSizing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.$store.dispatch('buttonSubmitStore/done');
  },
  methods: {
    clickedButton(event) {
      this.$store.dispatch('buttonSubmitStore/submit');
      this.$emit('callback', event);
    },
  },
  computed: {
    submitting() {
      return this.$store.getters['buttonSubmitStore/submitting'];
    },
    message() {
      return this.$store.getters['buttonSubmitStore/message'];
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonSubmit {
  display: inline-block;

  .button {
    background-color: $LincolnOrange;
    color: white;
    text-align: center;
    text-transform: uppercase;
    padding: 1em;
    display: inline-block;
    cursor: pointer;
    border: none;
    @include font-size(1rem);

    &.sized {
      width: $buttonWidth;
    }

    &:hover {
        background-color: $LincolnGrayDark;
    }

    &.disable {
      background-color: $LincolnGrayDark;
      pointer-events: none;
    }
  }

  .submitting {
    padding-left: 5px;
    display: inline-block;
    transform: translateY(1px);
  }

  .message {
    padding-left: 10px;
    display: inline-block;
    color: $LincolnOrange;
  }
}
</style>
